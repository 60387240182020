import React from "react";
import './App.css';

import {
  Routes,
  Route,


} from "react-router-dom";
import Login from './Components/Login/Login';
function App() {
  return (
    <Routes>
         <Route path='/Login' element={<Login />} />
      </Routes>
  );
}

export default App;
